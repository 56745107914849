import { Injectable } from '@angular/core';
import { Auth, signOut, user, User } from '@angular/fire/auth';
import { Router } from '@angular/router';

import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedIn: boolean = false;

  public currentFirebaseUser?: User;

  public currentUser?: any;

  constructor(
    public auth: Auth,
    private loaderService: LoaderService,
    private router: Router
  ) {
    user(this.auth).subscribe((_user) => {
      if (_user) {
        this.loggedIn = true;
        this.currentFirebaseUser = _user;
        this.getUser();
      } else {
        this.loggedIn = false;
      }
    });
  }

  async signOut() {
    this.loaderService.loading = true;
    this.loaderService.message = 'Cerrando sesión';

    await signOut(this.auth);

    this.currentUser = undefined;

    this.loaderService.loading = false;
    this.loaderService.message = '';

    sessionStorage.removeItem('user');
    this.loggedIn = false;
    this.router.navigate(['signin']);
  }

  async getUser() {
    this.loaderService.loading = true;
    this.loaderService.message = 'Cargando datos de usuario';

    const token = await this.currentFirebaseUser?.getIdToken();

    this.loaderService.loading = false;
    this.loaderService.message = '';
  }
}
